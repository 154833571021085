[data-component="PricingPage"] {

  padding: var(--unit-lg);

  .pricing-title {
    font-size: var(--font-size-xxxl);
    font-weight: var(--font-weight-medium);
    margin-top: calc(var(--unit-lg) * 2);
    margin-bottom: calc(var(--unit-lg) * 3);
    text-align: center;

    .subheading {
      font-weight: normal;
      font-size: var(--font-size-xl);
      color: var(--color-secondary);
    }
  }

  .pricing-plans {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--unit-lg);
  }

  .pricing-faq {
    margin: calc(var(--unit-lg) * 3) auto;
    max-width: var(--screen-sm);

    strong {
      font-weight: var(--font-weight-medium);
      color: var(--color-primary);
    }

    h1 {
      margin-bottom: calc(var(--unit-lg) * 3);
    }

    p {
      color: var(--color-secondary);
      font-size: var(--font-size-lg);
      margin-bottom: calc(var(--unit-lg) * 3);
    }
  }

}