.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--darken);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .modal-content {
    background: var(--background-secondary);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-default);
    border: 1px solid var(--outline-default);
    width: 600px;
    max-width: calc(100vw - var(--unit-xl) * 2);
    max-height: calc(100vh - var(--unit-xl) * 2);
    position: relative;

    .modal-toolbar {
      padding: var(--unit-md) var(--unit-lg);
      background: var(--background-tertiary);
      border-top-left-radius: var(--radius-lg);
      border-top-right-radius: var(--radius-lg);
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: calc(var(--unit-xl) * 1.5);

      h3 {
        margin: 0;
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-medium);
      }

      .close-button {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: none;
        color: var(--color-secondary);
        cursor: pointer;
        padding: 0;

        &:hover {
          color: var(--color-primary);
        }
      }
    }

    .modal-body {
      max-height: calc(100vh - var(--unit-xl) * 2 - var(--unit-xl) * 4);
      overflow: auto;
      padding: var(--unit-md);
    }

    .form-group {
      margin-bottom: var(--unit-lg);

      label {
        display: block;
        margin-bottom: var(--unit-sm);
        color: var(--color-secondary);
      }

      .add-column-container {
        display: flex;
        justify-content: flex-end;
        margin-top: var(--unit-md);
        padding-right: var(--unit-md);
      }
    }

    .modal-footer {
      padding: var(--unit-md) var(--unit-lg);
      background: var(--background-tertiary);
      border-bottom-left-radius: var(--radius-lg);
      border-bottom-right-radius: var(--radius-lg);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: var(--unit-sm);
      height: calc(var(--unit-xl) * 2);
    }

    .error-message {
      color: var(--red-main);
      padding: var(--unit-md);
      margin-bottom: var(--unit-md);
      background: var(--red-xdark);
      border-radius: var(--radius-lg);
    }
  }
}
