[data-component="Nav"] {
  position: relative;
  width: 100%;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: var(--unit-sm) var(--unit-lg);
  z-index: 999;

  &:after {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 1px;
    width: 100%;
    content: '';
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      var(--outline-hover) 40%,
      var(--outline-hover) 60%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  a, a:link, a:visited, a:active {
    color: var(--color-primary);
    font-weight: var(--font-weight-medium);
  }

  .nav-links {
    display: flex;
    align-items: center;
    margin: 0px var(--unit-xl);
    gap: var(--unit-xl);
  }

  .logo-wordmark, .logo-wordmark-small {
    display: flex;
    align-items: center;
    margin-top: -6px;
    img {
      height: var(--form-sm);
    }
  }

  .logo-wordmark-small {
    display: none;
  }

  @media (max-width: 768px) {
    .nav-links {
      gap: var(--unit-lg);
      margin: 0px var(--unit-lg);
    }
    .logo-wordmark {
      display: none;
    }
    .logo-wordmark-small {
      display: flex;
    }
  }

  .user-info {
    position: relative;
    height: var(--form-sm);
    display: flex;
    align-items: center;
    padding: var(--unit-md);
    cursor: pointer;
    border-radius: var(--radius-lg);
    background-color: var(--background-primary);
    border: 1px solid var(--outline-default);
    box-shadow: var(--shadow-default);
    &:hover {
      background: var(--background-secondary);

    }
    a, a:link, a:visited, a:active {
      color: var(--link-color);
    }
    .user-name {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      gap: var(--unit-sm);
      img.icon,
      svg {
        width: 16px;
        height: 16px;
        color: var(--color-tertiary);
      }
    }
    .user-menu-container {
      position: absolute;
      top: 100%;
      left: 100%;
      right: 0%;
      overflow: visible;
      z-index: 1;
    }
    .user-menu {
      position: relative;
      background: var(--background-secondary);
      border-radius: var(--radius-lg);
      box-shadow: var(--shadow-default);
      border: 1px solid var(--outline-default);
      overflow: hidden;
      width: 250px;
      padding: var(--unit-md) 0px;
      transform: translateX(-100%);
      hr {
        width: 100%;
        height: 1px;
        border: none;
        border-top: 1px solid var(--outline-default);
        background: transparent;
        margin: var(--unit-md) 0px;
      }
      li {
        display: flex;
        position: relative;
        width: 100%;
        & > span,
        & > a {
          display: flex;
          padding: var(--unit-md);
          flex-grow: 1;
          align-items: center;
          gap: var(--unit-md);
          & > .icon {
            width: 16px;
            height: 16px;
            color: var(--color-tertiary);
          }
          &.center {
            justify-content: center;
          }
        }
        & > a:hover {
          background: var(--background-primary);
          text-decoration: none;
        }
      }
    }
  }

  .credit-balance {
    display: inline-flex;
    align-items: center;
    .microcents {
      display: none;
      font-size: var(--font-size-xxs);
      margin-bottom: var(--unit);
    }
  }

}