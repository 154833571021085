[data-component="ChatMessage"] {
  display: flex;
  flex-direction: column;
  margin-top: calc(var(--unit-md) * 1.5);
  padding: var(--unit-sm);
  &:hover {
    background-color: var(--background-primary);
  }
  .chat-heading {
    display: flex;
  }
  .chat-body {
    display: flex;
    font-size: var(--font-size-md);
    color: var(--color-secondary);

    .chat-avatar {
      position: relative;
      display: flex !important;
      font-weight: var(--font-weight-bold);
      color: var(--white);
      width: var(--unit-xl);
      height: var(--unit-xl);
      border-radius: 1000px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin: 0px var(--unit-lg);
      margin-top: var(--unit);
      background: var(--green-main);
      &.app {
        background: var(--blurple);
      }
      &.image {
        background: none;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    .chat-content {
      flex-grow: 1;
      display: flex !important;
      flex-direction: column;
      overflow: hidden;
      .chat-author {
        display: flex !important;
        align-items: baseline;
        gap: var(--unit-md);
        .chat-author-name {
          color: var(--color-primary);
          display: flex !important;
          align-items: center;
          gap: var(--unit-sm);
          .app {
            background: var(--blurple);
            padding: 0px var(--unit);
            border-radius: var(--radius);
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size-xs);
          }
        }
        .chat-time {
          color: var(--color-disabled);
          font-size: var(--font-size-sm);
        }
      }
      .chat-message {
        overflow: auto;
        line-height: var(--line-height-lg);
        white-space: pre-wrap;

        .chat-notification {
          display: block;
          border-radius: var(--radius-lg);
          margin-top: var(--unit-md);
          font-size: var(--font-size-sm);
          color: var(--color-disabled);
        }
      }
      .functions {
        overflow: auto;
        &:after {
          display: block;
          clear: both;
          content: '';
        }
        .function-details {
          display: flex !important;
          flex-direction: column;
          float: left;
          margin-top: var(--unit-md);
          background: var(--background-tertiary);
          border-radius: var(--radius-lg);
          padding: var(--unit-md);
          font-size: var(--font-size-sm);
          .function-name {
            display: flex !important;
            align-items: center;
            font-weight: var(--font-weight-medium);
            svg {
              width: var(--unit-lg);
              height: var(--unit-lg);
              margin-right: var(--unit-md);
              color: var(--orange-main);
              fill: currentColor;
            }
          }
          pre {
            border-radius: var(--radius);
            background: var(--background-primary);
            padding: var(--unit-sm);
            font-size: var(--font-size-xs);
            margin: 0px;
            margin-top: var(--unit-sm);
            max-height: calc(var(--unit-xl) * 4);
            overflow: auto;
          }
        }
      }
      .sql {
        overflow: auto;
        margin-top: var(--unit-md);
        background: var(--background-tertiary);
        border-radius: var(--radius-lg);
        font-size: var(--font-size-xs);

        &:hover {
          background: var(--background-secondary);
        }
        
        .query-container {
          display: flex;
          align-items: center;
          padding: var(--unit-md);
          gap: var(--unit-md);
          cursor: pointer;

          .expand-icon {
            color: var(--color-secondary);
          }

          .query {
            margin: 0;
            padding: 0;
            background: none;
            flex: 1;
            font-family: var(--font-family-monospace);
            white-space: pre-wrap;
            word-break: break-word;
          }
        }

        .result {
          margin: var(--unit-md);
          background: var(--background-primary);
          margin-top: var(--unit-sm);
          padding: var(--unit-md);
          border-radius: var(--radius-lg);
          white-space: pre-wrap;
          word-break: break-word;
        }
      }
    }
  }
}