[data-component="InsertRowEditor"] {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--darken);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .modal-content {
      background: var(--background-secondary);
      border-radius: var(--radius-lg);
      box-shadow: var(--shadow-default);
      border: 1px solid var(--outline-default);
      width: 500px;
      max-width: calc(100vw - var(--unit-xl) * 2);

      .modal-toolbar {
        padding: var(--unit-md);
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
          margin: 0;
          font-size: var(--font-size-md);
          font-weight: var(--font-weight-medium);
        }

        .close-button {
          padding: 0;
          background: none;
          border: none;
          color: var(--color-secondary);
          cursor: pointer;

          &:hover {
            color: var(--color-primary);
          }
        }
      }

      .modal-body {
        padding: var(--unit-md);

        .form-group {
          display: flex;
          flex-direction: column;
          gap: var(--unit-md);
        }
      }

      .modal-footer {
        padding: var(--unit-md) var(--unit-lg);
        background: var(--background-tertiary);
        border-bottom-left-radius: var(--radius-lg);
        border-bottom-right-radius: var(--radius-lg);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: var(--unit-sm);
        height: calc(var(--unit-xl) * 2);
      }
    }
  }
}
