[data-component="BillingMethodsPage"] {

  padding: 0px var(--unit-lg);
  padding-bottom: var(--unit-lg);

  .billing-methods-actions {
    padding: var(--unit-md) 0px;
    display: flex;
    justify-content: flex-start;
  }

  .billing-methods {

    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: var(--unit-md);

  }

}

[data-component="PaymentMethodCard"] {

  background-color: var(--background-secondary);
  border-radius: var(--radius-lg);
  padding: var(--unit-md);
  width: 300px;

  .card-actions {
    display: flex;
    gap: var(--unit-md);
    justify-content: flex-end;
    margin-top: var(--unit-md);
  }

}