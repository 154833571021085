[data-component="SignupPage"],
[data-component="LoginPage"],
[data-component="Login3pPage"] {
  position: relative;
  width: 100%;
  height: 100%;

  .center-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .center-modal {
      width: 480px;
      max-width: calc(100% - var(--unit-xl) * 2);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: var(--radius-lg);
      background: var(--background-secondary);
      margin: var(--unit-lg);
      padding: var(--unit-lg) var(--unit-xl);
    }
  }

  .form-intro {
    width: 100%;
    margin: 0px 4px;
    padding-bottom: var(--unit-lg);
    margin-bottom: var(--unit-lg);
    border-bottom: 1px solid var(--outline-default);
    text-align: center;
  }
  
  .show-username {
    width: 100%;
    &.hidden {
      display: none;
    }
  }

  .login-discord {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: calc(var(--unit-lg) * 0.5);
    &.login-3p {
      padding-top: 0px;
    }
  }

  .or-row {
    display: flex;
    align-items: center;
    margin-top: calc(var(--unit-lg) * 2);
    margin-bottom: var(--unit-md);
    width: 100%;
    height: 1px;
    overflow: visible;

    .line {
      flex-grow: 1;
      border-top: 1px solid var(--outline-default);
    }

    .or-text {
      padding: 0px var(--unit-lg);
    }
  }
}