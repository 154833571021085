[data-component="AlertModal"] {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: var(--darken);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  font-size: var(--font-size-md);
  z-index: 1000;
  .modal {
    position: relative;
    border-radius: var(--radius-xl);
    box-shadow: var(--shadow-default);
    padding: var(--unit-md);
    background: var(--background-secondary);
    border: 1px solid var(--outline-default);
    max-width: calc(100vw - var(--unit-xl) * 2);
    max-height: calc(100vh - var(--unit-xl) * 2);
    width: var(--screen-xs);
    overflow-x: hidden;
    overflow-y: auto;
    .message {
      padding: var(--unit-md);
      white-space: pre-wrap;
      word-break: break-word;
      overflow-wrap: break-word;
    }
    .actions {
      padding: var(--unit-md);
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: var(--unit-md);
    }
  }
}