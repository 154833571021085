[data-component="ChatBox"] {

  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: var(--radius-xl);
  background: var(--background-secondary);
  padding: var(--unit-lg);
  display: flex;
  flex-direction: column;
  position: relative;

  .scrollable {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;
    padding-bottom: var(--unit-md);
  }
  .fixed {
    display: flex;
    flex-shrink: 0;
  }

  .welcome {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-weight-bold);
    padding-left: var(--unit-md);
  }

  .description {
    font-size: var(--font-size-md);
    color: var(--color-tertiary);
    margin: 0px;
    padding-left: var(--unit-md);
    margin-bottom: var(--unit-xl);
  }

  mark {
    background-color: var(--blurple);
    color: var(--white);
    padding: var(--unit) var(--unit-sm);
    opacity: 0.8;
    border-radius: var(--radius);
    cursor: pointer;
    transform: opacity ease-in-out 0.1s;
    &:hover {
      opacity: 1.0;
    }
  }

}