[data-component="DashboardNav"] {

  position: relative;
  display: flex;
  gap: var(--unit-lg);
  padding: var(--unit-md) var(--unit-lg);
  z-index: 998;

  .nav-link {
    padding: var(--unit-md);
    padding-bottom: var(--unit-sm);
    &.nav-active {
      font-weight: var(--font-weight-medium);
      background-color: var(--background-primary);
      border-bottom: 3px solid var(--color-primary);
      a {
        text-decoration: none !important;
        color: var(--color-primary) !important;
      }
    }
  }

}