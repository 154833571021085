[data-component="ImageUploader"] {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  input[type="file"] {
    display: none;
  }
  .image-actions {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: var(--unit-sm);
    .reset, .close, .upload {
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--unit-sm);
      background-color: var(--background-primary);
      cursor: pointer;
      svg {
        width: var(--unit-lg);
        height: var(--unit-lg);
      }
      &:hover {
        background-color: var(--red-main);
      }
    }
    .reset:hover {
      background-color: var(--blue-main);
    }
    .upload:hover {
      background-color: var(--green-main);
    }
    &.bottom {
      top: auto;
      bottom: 0px;
    }
  }
  &:not(:hover) {
    .image-actions {
      display: none;
    }
  }
  img, div.img {
    width: 96px;
    height: 96px;
    display: block;
    border: 1px solid var(--outline-default);
    border-radius: var(--radius-lg);
    background-color: var(--background-primary);
    background-size: cover;
    background-repeat: no-repeat;
    font-size: var(--font-size-sm);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    object-fit: cover;
    object-position: center;
    color: var(--color-disabled);
    user-select: none;
    cursor: pointer;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}