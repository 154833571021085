[data-component="SetupPage"] {

  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-xl);

  .content {
    position: relative;
    border-radius: var(--radius-xl);
    background: var(--background-secondary);
    padding: var(--unit-xl);
    margin: var(--unit-xl);
    width: var(--screen-xs);
    max-width: calc(100% - var(--unit-xl) * 2);
    max-height: calc(100% - var(--unit-xl) * 2);
    overflow: auto;
    text-align: center;
  }

  .package-endpoints {
    position: relative;
    text-align: left;
    width: 100%;
    display: block;
    font-size: var(--font-size-sm);
    .package-endpoint {
      padding: var(--unit-md) var(--unit-lg);
      color: var(--color-secondary);
      display: flex;
      align-items: center;
      &:not(:first-child) {
        border-top: 1px solid var(--outline-default);
      }
      .info {
        .title, .summary {
          display: block;
          line-height: var(--line-height-lg);
          display: flex;
          align-items: center;
          gap: 8px;
          .highlight {
            color: var(--color-primary);
            font-weight: var(--font-weight-medium);
          }
          .method {
            font-weight: var(--font-weight-medium);
            padding: 0px var(--unit-sm);
            border-radius: var(--radius-lg);
            background: var(--green-dark);
            color: var(--white);
            &.method-DELETE {
              background: var(--red-dark);
            }
            &.method-POST {
              background: var(--blue-dark);
            }
            &.method-PUT {
              background: var(--orange-dark);
            }
          }
          .time {
            color: var(--color-disabled);
          }
        }
      }
    }
  }

}