[data-component="Footer"] {
  position: relative;
  width: 100%;
  display: flex !important;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px var(--unit-lg);
  gap: var(--unit-xl);

  &:after {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 1px;
    width: 100%;
    content: '';
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      var(--outline-hover) 40%,
      var(--outline-hover) 60%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .footer-column {
    display: flex !important;
    flex-direction: column;
    padding: var(--unit-lg) 0px;
    .footer-title {
      font-weight: var(--font-weight-medium);
      padding-bottom: var(--unit-md);
    }
  }
  .footer-right {
    flex-shrink: 0;
    justify-content: flex-end;
    align-items: flex-end;
    color: var(--color-secondary);
    flex-grow: 1;
    .copyright {
      display: flex;
      align-items: center;
      gap: var(--unit-md);
      img {
        height: var(--form-md);
      }
    }
  }
}