[data-component="ColumnEditor"] {
  display: flex;
  width: 100%;
  align-items: center;
  gap: var(--unit-sm);
  padding: var(--unit-sm) var(--unit-md);
  border-radius: var(--radius-lg);
  background: transparent;
  position: relative;

  &:not(:first-child):hover:not(.dragging) {
    background: var(--background-tertiary);
  }

  &.dragging {
    background: var(--background-primary);
    pointer-events: none;
    
    * {
      visibility: hidden;
    }
  }

  .drag-handle {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-disabled);
    margin-right: var(--unit-md);

    &:not(.empty) {
      cursor: grab;
    }

    &:hover {
      color: var(--color-primary);
    }
    
    &:active {
      cursor: grabbing;
    }
  }

  .column-name-container {
    display: flex;
    align-items: center;
    gap: var(--unit-sm);
    flex: 1;
    flex-grow: 1;

    .original-name {
      width: calc(var(--unit-xl) * 4);
      color: var(--color-secondary);
      font-size: var(--font-size-sm);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  [data-component="Select"] {
    width: auto;
  }

  .select-wrapper {
    width: 120px;
  }

  .delete-button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    color: var(--color-disabled);
    cursor: pointer;
    padding: 0;
    margin-left: var(--unit-sm);

    &:hover {
      color: var(--red-text);
    }
  }
}

.drop-zone {
  height: 2px;
  margin: var(--unit-xs) 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background: var(--blue-main);
    opacity: 0;
    transition: opacity 0.1s ease;
  }

  &.active::after {
    opacity: 1;
  }
}
