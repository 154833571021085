[data-component="PackageCard"] {
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
  padding: var(--unit-md);
  .details {
    display: flex;
    align-items: center;
    .name {
      font-weight: var(--font-weight-medium);
    }
    .time {
      color: var(--color-disabled);
    }
  }
}