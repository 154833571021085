[data-component="EditProfilePage"] {

  padding: 0px var(--unit-lg);
  padding-bottom: var(--unit-lg);

  .profile-edit {

    margin: 0px auto;
    width: 100%;
    max-width: var(--screen-sm);

    .profile-row {
      display: flex;
      margin-bottom: var(--unit-md);

      .profile-title {
        font-weight: var(--font-weight-medium);
        min-width: 200px;
        flex-shrink: 0;
        height: var(--form-md);
        display: flex;
        align-items: center;
      }

      .profile-entry {
        flex-grow: 1;
      }
    }

    .profile-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

  }

}