[data-component="PackagesPage"] {

  .packages-list {
    max-width: var(--screen-sm);
    margin: 0px auto;
    & > [data-component] {
      margin: var(--unit-md) 0px;
    }
  }

}