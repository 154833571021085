[data-component="Sidebar"] {

  position: relative;
  margin: 0px var(--unit-lg);
  margin-bottom: var(--unit-lg);
  padding: var(--unit-sm) var(--unit-md);
  background-color: var(--background-secondary);
  border-radius: var(--radius-lg);

  .sidebar-link {

    position: relative;
    padding: var(--unit-md);

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--outline-default);
    }

    &.sidebar-active {
      font-weight: var(--font-weight-medium);
      a {
        text-decoration: none !important;
        color: var(--color-primary) !important;
      }
    }

  }

}