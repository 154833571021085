[data-component="DashboardPage"] {

  .item-title {
    font-weight: var(--font-weight-bold);
    margin: var(--unit-md);
    padding: 0px var(--unit-md);
    display: flex;
    align-items: center;
    gap: var(--unit-md);
    & > span {
      font-weight: normal;
    }
  }

  .item-description {
    padding: 0px var(--unit-md);
    margin: 0px var(--unit-md);
    margin-bottom: var(--unit-md);
    color: var(--color-secondary);
    strong {
      font-weight: var(--font-weight-medium);
    }
  }

  .item-group {
    &:after {
      display: block;
      content: '';
      clear: both;
      margin-bottom: var(--unit-xl);
    }
    .item-entry {
      float: left;
      border-radius: var(--radius-lg);
      margin: var(--unit-md);
      background: var(--background-secondary);
      padding: var(--unit-md);
      width: 300px;
      position: relative;
    }
  }

}