[data-component="data-table-cell"] {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &:focus-within {
    box-shadow: inset 0 0 0 1px var(--blue-main);
  }

  &:not(:hover):not([data-editing="true"]) {
    .action-button {
      display: none;
    }
  }
  
  .cell-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
    padding: var(--unit-md);
    line-height: calc(var(--unit-md) * 2);
    min-height: calc(var(--unit-md) * 2 + var(--unit-md) * 2);

    .null-value {
      display: inline-block;
      color: var(--color-disabled);
      margin: -2px 0px;
      font-family: var(--font-family-monospace);
    }
  }

  textarea {
    position: relative;
    display: block;
    width: 100%;
    z-index: 2;
    padding: var(--unit-md);
    line-height: calc(var(--unit-md) * 2);
    min-height: calc(var(--unit-md) * 2 + var(--unit-md) * 2);
    margin: 0;
    border: none;
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    resize: none;
    outline: none;
    color: var(--color-primary);
  }

  .action-buttons {
    position: absolute;
    bottom: var(--unit-sm);
    right: var(--unit-sm);
    gap: var(--unit-sm);
    display: flex;
    align-items: center;

    .action-button {
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      padding: 0;
      border: 1px solid var(--outline-default);
      border-radius: var(--radius);
      background: var(--background-primary);
      color: var(--color-secondary);
      cursor: pointer;
      transition: all 0.2s ease;

      &.null-button {
        padding: 0 var(--unit-md);
        width: auto;
        font-family: var(--font-family-monospace);
      }
  
      &:hover {
        background: var(--background-tertiary);
        border-color: var(--outline-hover);
        color: var(--color-primary);
      }
    }
  }
}
