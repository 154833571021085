[data-component="BillingSubscriptionPage"] {

  padding: 0px var(--unit-lg);
  padding-bottom: var(--unit-lg);

  .active-plan {
    display: flex;
    margin-bottom: var(--unit-lg);
  }

  .pricing-plans {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--unit-lg);
  }

  .subscription-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--unit-lg);
  }

}