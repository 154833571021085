
[data-component="PricingCard"] {

  width: 300px;
  display: flex;
  flex-direction: column;
  transition: transform 0.1s ease;
  position: relative;

  &.pricing-horizontal {
    width: 100%;
    .plan-title {
      margin-bottom: var(--unit-md) !important;
      flex-direction: row !important;
      gap: var(--unit-lg);
    }
    .plan-body {
      display: flex;
      flex-wrap: wrap;
      .plan-features {
        margin-bottom: 0px !important;
        li {
          margin-bottom: var(--unit-sm) !important;
          .feature-details {
            display: flex;
            flex-direction: row !important;
            flex-wrap: wrap;
          }
          .feature-name {
            margin-right: var(--unit-md);
          }
          .feature-value {
            display: flex;
            align-items: center;
          }
        }
      }
      .select-plan {
        margin-left: var(--unit-md);
        margin-top: var(--unit-lg);
        flex-grow: 1;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }

  .plan-border {
    border-radius: var(--radius-lg);
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    &:not(.featured) {
      display: none;
    }
    &:before {
      position: absolute;
      top: -500%;
      left: -500%;
      right: -500%;
      bottom: -500%;
      content: '';
      background: conic-gradient(
        red, 
        orange, 
        yellow, 
        green, 
        cyan, 
        blue, 
        violet,
        red
      );
      animation: loading-spin 60s linear infinite;
    }
  }

  .plan-content {
    position: relative;
    border-radius: var(--radius-lg);
    z-index: 2;
    background-color: var(--background-secondary);
    margin: 1px;
    padding: var(--unit-lg);
  }

  .plan-float {
    position: absolute;
    top: var(--unit-md);
    right: var(--unit-md);
    display: flex;
    align-items: center;
    gap: var(--unit-md);

    .plan-featured, .plan-active {
      position: relative;
      padding: var(--unit-sm) var(--unit-md);
      border: 1px solid var(--outline-default);
      border-radius: var(--radius);
      font-size: var(--font-size-xxs);
      font-weight: var(--font-weight-bold);
      letter-spacing: 1px;
      background-color: var(--background-primary);
    }

    .plan-active {
      background-color: var(--green-main);
    }
  }

  &:hover {
    z-index: 99;
  }

  .plan-title {
    display: flex;
    flex-direction: column;

    .plan-name {
      font-size: var(--font-size-xl);
      font-weight: bold;
    }
  
    .plan-price {
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-medium);
      color: var(--blue-text);
  
      .period {
        margin-left: var(--unit-md);
        font-size: var(--font-size-md);
        color: var(--color-secondary);
      }
  
    }

    margin-bottom: calc(var(--unit-lg) * 2);

  }

  .plan-features {
    list-style-type: none;
    padding: 0;
    margin: 0 0 var(--unit-lg) 0;

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;

      .feature-icon {
        margin-right: var(--unit-md);
        flex-shrink: 0;

        svg {
          width: var(--unit-lg);
          height: var(--unit-lg);
        }

        &.check {
          color: var(--green-main);
        }

        &.info {
          margin-left: var(--unit-md);
          margin-right: 0px;
          stroke-width: 3;
          border-radius: 100px;
          background-color: var(--background-primary);
          padding: 4px;
          color: var(--color-disabled);
        }

      }

      .feature-details {
        display: flex;
        flex-direction: column;
      }

      .feature-name {
        display: flex;
        align-items: center;
        font-weight: var(--font-weight-medium);
        
        .feature-info {
          position: relative;
          font-weight: normal;
          display: flex;
          align-items: center;
          &:not(:hover) {
            .feature-info-popup {
              display: none;
            }
          }
          .feature-info-popup {
            position: absolute;
            bottom: 100%;
            word-wrap: none;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            overflow: visible;
            left: calc(50% - 100px);
            right: calc(50% - 100px);
            background: var(--background-primary);
            border-radius: var(--radius);
            padding: var(--unit-md);
            border: 1px solid var(--outline-default);
          }
        }

      }

      .feature-value {
        color: var(--color-secondary);
      }
    }
  }

  .select-plan {
    display: flex;
    justify-content: center;
    margin-top: calc(var(--unit-lg) * 2);
  }

}