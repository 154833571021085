[data-component="AgentsPage"] {

  width: 100%;
  height: 100%;
  display: flex;
  font-size: var(--font-size-xl);

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    width: auto;
    height: auto;
    .settings {
      width: auto !important;
      height: auto !important;
      display: block;
    }
    .chat {
      width: auto !important;
      height: auto !important;
      min-height: 30vh;
      max-height: 60vh;
      display: block;
      margin: var(--unit-xl) !important;
    }
  }

  .edit-name-and-avatar {
    display: flex;
    align-items: flex-start;
    gap: var(--unit-md);
    .edit-name {
      flex-grow: 1;
    }
    .edit-avatar {
      flex-shrink: 0;
    }
  }

  .settings {
    position: relative;
    border-radius: var(--radius-xl);
    background: var(--background-secondary);
    padding: var(--unit-lg);
    margin: var(--unit-xl);
    width: var(--screen-xs);
    height: calc(100% - var(--unit-xl) * 2);
    display: flex;
    flex-direction: column;
    .scrollable {
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      max-width: 100%;
      padding-bottom: var(--unit-md);
    }
    .fixed {
      display: flex;
      flex-shrink: 0;
    }
  }

  .chat {
    flex-grow: 1;
    height: calc(100% - var(--unit-xl) * 2);
    margin: var(--unit-xl);
    margin-left: 0px;
    position: relative;
    .toggle-devmode {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 1;
      background-color: var(--background-secondary);
      border-radius: var(--radius-lg);
      padding: var(--unit-md);
    }
  }

  .settings {
    flex-shrink: 0;
    .discord-links {
      display: block;
      font-size: var(--font-size-md);
      .discord-link-entry {
        display: flex;
        align-items: center;
        color: var(--color-secondary);
        margin: var(--unit-md);
        margin-right: 0px;
        gap: var(--unit-md);
        strong {
          font-weight: var(--font-weight-medium);
          color: var(--color-primary);
        }
        svg {
          width: var(--unit-lg);
          height: var(--unit-lg);
          color: var(--outline-hover);
        }
        & > * {
          display: flex;
          align-items: center;
        }
        .warn {
          font-size: var(--font-size-sm);
          color: var(--orange-main);
        }
      }
    }
  }

  .package-endpoints {
    position: relative;
    text-align: left;
    width: 100%;
    display: block;
    font-size: var(--font-size-sm);
    .package-endpoint {
      padding: var(--unit-md) var(--unit-lg);
      color: var(--color-secondary);
      display: flex;
      align-items: center;
      &:not(:first-child) {
        border-top: 1px solid var(--outline-default);
      }
      .info {
        .title, .summary {
          display: block;
          line-height: var(--line-height-lg);
          display: flex;
          align-items: center;
          gap: 8px;
          .highlight {
            color: var(--color-primary);
            font-weight: var(--font-weight-medium);
          }
          .method {
            font-weight: var(--font-weight-medium);
            padding: 0px var(--unit-sm);
            border-radius: var(--radius-lg);
            background: var(--green-dark);
            color: var(--white);
            &.method-DELETE {
              background: var(--red-dark);
            }
            &.method-POST {
              background: var(--blue-dark);
            }
            &.method-PUT {
              background: var(--orange-dark);
            }
          }
          .time {
            color: var(--color-disabled);
          }
        }
      }
    }
  }

}