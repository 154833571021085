[data-component="Textbox"] {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  &.error {
    textarea, input {
      border-color: var(--red-text) !important;
    }
  }
  .heading {
    font-size: var(--font-size-sm);
    padding: var(--unit-sm);
    display: flex;
    align-items: flex-end;
    height: var(--unit-xl);
    font-weight: var(--font-weight-medium);
  }
  .error-text {
    margin: 0px 1px;
    padding: 0px var(--unit-lg);
    font-size: var(--font-size-sm);
    color: var(--red-text);
  }
  textarea, input {
    width: 100%;
    padding: var(--unit-md) var(--unit-lg);
    font-family: var(--font-family);
    background-color: var(--background-primary);
    color: var(--color-primary);
    font-size: var(--font-size-md);
    line-height: calc(var(--form-md) - var(--unit-md) * 2 - 2px);
    min-height: var(--form-md);
    border-radius: var(--radius-lg);
    border: 1px solid var(--outline-default);
    caret-color: var(--color-tertiary);
    outline: none;
    resize: none;
    animation:
      border-color 0.1s ease-in-out;
    &:hover {
      border-color: var(--outline-hover);
    }
    &:focus {
      border-color: var(--outline-active);
      outline: none;
    }
    &.monospace {
      font-family: "Source Code Pro", Menlo, Monaco, Consolas, monospace;
      font-size: var(--font-size-sm);
      letter-spacing: -0.2px;
    }
    &[readonly] {
      cursor: default;
      color: var(--color-disabled);
      &:hover, &:focus {
        border-color: var(--outline-default);
      }
    }
  }
  .textbox-container {
    position: relative;
    display: flex;
    .end-icon {
      position: absolute;
      pointer-events: none;
      right: 0px;
      height: var(--form-md);
      bottom: 0px;
      display: flex;
      align-items: center;
      padding: 0px var(--unit-md);
      gap: var(--unit-md);
      svg {
        color: inherit;
        width: 24px;
        height: 24px;
        stroke-width: 2px;
        color: var(--color-disabled);
      }
    }
  }
  &[data-size="small"] {
    textarea, input {
      font-size: var(--font-size-sm);
      line-height: calc(var(--form-sm) - 2px);
      min-height: var(--form-sm);
      padding: 0px var(--unit-md);
    }
    .textbox-container .end-icon {
      height: var(--form-sm);
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}