[data-component="DatabasesPage"] {
  padding: var(--unit-lg);

  .databases-title {
    display: flex;
    align-items: center;
    gap: var(--unit-md);
    padding: 0px calc(var(--unit-md) * 3);
    border-left: 1px solid transparent; // for balancing pixel-perfection
    border-right: 1px solid transparent;
    svg {
      width: var(--unit-lg);
      height: var(--unit-lg);
      color: var(--color-disabled);
    }

    &-name {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-lg);
    }

    &-agent {
      display: flex;
      align-items: center;
      gap: var(--unit-md);
      color: var(--text-color-secondary);
      font-size: var(--font-size-md);

      svg {
        width: calc(var(--unit-md) + var(--unit-sm));
        height: calc(var(--unit-md) + var(--unit-sm));
        color: var(--blue-main);
      }
    }
  }

  .databases-sql-editor {
    position: relative;
    .databases-sql-editor-actions {
      display: block;
      position: absolute;
      right: var(--unit-md);
      bottom: var(--unit-md);
    }
  }

  .databases-content {
    display: flex;
    flex-direction: column;
    gap: var(--unit-md);

    .databases-tabs {
      display: flex;
      flex-wrap: wrap;
      gap: var(--unit-sm);
      padding: 0px var(--unit-md);
      border-bottom: 1px solid var(--outline-default);
      
      .tab {
        position: relative;
        display: flex;
        align-items: center;
        z-index: 1;
        font-weight: var(--font-weight-medium);
        gap: var(--unit-sm);
        padding: var(--unit-md) var(--unit-lg);
        border: 1px solid var(--outline-default);
        border-bottom-color: transparent;
        border-radius: var(--radius-lg) var(--radius-lg) 0 0;
        background: transparent;
        color: var(--color-disabled);
        font-family: inherit;
        font-size: var(--font-size-sm);
        cursor: pointer;
        white-space: nowrap;
        margin-bottom: -1px;

        &:hover:not(.active) {
          background: var(--background-tertiary);
          color: var(--color-primary);
          border-color: var(--outline-default);
        }

        &.active {
          color: var(--color-primary);
          background: var(--background-primary);
          border-color: var(--outline-default);
          border-bottom-color: var(--background-primary);
          
          svg {
            color: var(--blue-main);
          }
        }

        &.new-table {
          margin-left: auto;  // Push to right
          color: var(--color-secondary);
          
          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }

    .databases-table {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      gap: var(--unit-md);

      .table-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-end;
        gap: var(--unit-sm);
        .natural-language-input {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          position: relative;
          gap: var(--unit-sm);
          [data-component="Textbox"] {
            width: 250px;
          }
        }
      }

      .no-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: calc(var(--unit-lg) * 4);
        color: var(--color-disabled);
        p {
          margin: var(--unit-lg);
        }
      }
    }
  }
}