[data-component="App"] {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > [data-component="Nav"] {
    flex-shrink: 0;
    backdrop-filter: blur(8px) brightness(0.5);
    -webkit-backdrop-filter: blur(8px) brightness(0.5);
  }

  & > [data-component="DashboardNav"] {
    backdrop-filter: blur(8px) brightness(0.5);
    -webkit-backdrop-filter: blur(8px) brightness(0.5);
  }

  & > .page-content {
    flex-grow: 1;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: calc(-1 * var(--navbar-height));
    padding-top: var(--navbar-height);
    & > :first-child {
      flex-grow: 1;
    }
  }

  & > .page-content-with-sidebar {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: auto;
    & > .page-sidebar {
      width: 250px;
      flex-shrink: 0;
      overflow: auto;
    }
    & > .page-content {
      flex-grow: 1;
      overflow: auto;
    }
    @media (max-width: 767px) {
      display: block;
      & > .page-sidebar {
        width: 100%;
      }
    }
  }

  .form-row {
    width: 100%;
    display: flex;
    align-items: center;
    &.margin-top {
      margin-top: var(--unit-lg);
    }
    &.align-right {
      justify-content: flex-end;
    }
    &.error {
      min-height: var(--form-md);
      font-size: var(--font-size-sm);
      background: var(--red-dark);
      border: 1px solid var(--outline-default);
      color: var(--red-light);
      border-radius: var(--radius);
      padding: var(--unit-md) var(--unit-lg);
    }
    .link {
      font-size: var(--font-size-sm);
      padding: 0px var(--unit-lg);
      display: flex;
      align-items: center;
    }

  }

  .spacer {
    flex-grow: 1;
  }

}