[data-component="BillingCreditsPage"] {

  position: relative;
  width: 100%;
  height: 100%;
  padding: 0px var(--unit-lg);
  font-size: var(--font-size-lg);

  .content {
    position: relative;
    border-radius: var(--radius-xl);
    background: var(--background-secondary);
    padding: var(--unit-xl);
    max-height: calc(100% - var(--unit-xl) * 2);
    overflow: auto;
    text-align: center;
  }

  .complete-message {
    border: 1px solid var(--outline-default);
    color: var(--white);
    border-radius: var(--radius-lg);
    margin-bottom: var(--unit-xl);
    font-size: var(--font-size-md);
    padding: var(--unit-md);
    &.success {
      background: var(--green-dark);
    }
    &.failure {
      background: var(--red-dark);
    }
  }

  .my-balance {
    display: flex;
    width: 100%;
    justify-content: center;
    .credit-balance {
      display: flex;
      align-items: center;
      font-size: var(--font-size-xxl);
      .microcents {
        font-size: var(--font-size-lg);
        margin-bottom: var(--unit-lg);
      }
    }
  }

  .purchase-credits {
    margin: 0px auto;
    margin-top: var(--unit-xl);
    max-width: 400px;
    .add-dropdown {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: var(--unit-md);
      & > [data-component] {
        width: auto;
        flex-shrink: 0;
        &[data-component="Select"] {
          flex-grow: 1;
        }
      }
    }
  }

}