[data-component="ErrorPage"] {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-xl);

  .content {
    position: relative;
    border-radius: var(--radius-xl);
    background: var(--background-secondary);
    padding: var(--unit-xl);
    margin: var(--unit-xl);
    width: var(--screen-xs);
    max-width: calc(100% - var(--unit-xl) * 2);
    max-height: calc(100% - var(--unit-xl) * 2);
    overflow: auto;
    text-align: center;
  }

  .error-code {
    font-size: var(--font-size-xxxl);
  }

  .error-description {
    margin-top: var(--unit-lg);
  }

}