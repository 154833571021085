@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&display=swap');

@keyframes loading-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes gem-float {
  0% { transform: translateY(2px); }
  40% { transform: translateY(-7px); }
  100% { transform: translateY(2px); }
}

.loading-spin {
  animation: loading-spin 1.0s ease-in-out infinite;
}

@media only screen and (max-width: 768px) {
  .hide-mobile {
      display: none !important;
  }
}

:root {

  /* color */
  --color-primary: #f8f8f8;
  --color-secondary: #c0c0c0;
  --color-tertiary: #a0a0a0;
  --color-disabled: #808080;
  --background-primary: #000000;
  --background-secondary: #1a1a1a;
  --background-tertiary: #222222;

  /* font */
  --font-family: "Assistant", sans-serif;
  --font-family-serif: "Libre Bodoni", serif;
  --font-family-monospace: "Source Code Pro", Menlo, Monaco, Consolas, monospace;
  --font-weight-normal: 400;
  --font-weight-medium: 600;
  --font-weight-bold: 700;
  --font-size-xxs: 9px;
  --font-size-xs: 11px;
  --font-size-sm: 13px;
  --font-size-md: 15px;
  --font-size-lg: 20px;
  --font-size-xl: 26px;
  --font-size-xxl: 32px;
  --font-size-xxxl: 48px;
  --line-height-sm: 1.2em;
  --line-height-md: 1.4em;
  --line-height-lg: 1.6em;
  --line-height-xl: 2.0em;

  /* size */
  --unit: 2px;
  --unit-sm: 4px;
  --unit-md: 8px;
  --unit-lg: 16px;
  --unit-xl: 32px;

  --radius: 3px;
  --radius-lg: 6px;
  --radius-xl: 12px;

  --form-sm: 32px;
  --form-md: 48px;
  --form-lg: 64px;

  --screen-xs: 512px;
  --screen-sm: 768px;
  --screen-md: 1024px;
  --screen-lg: 1920px;

  --navbar-height: var(--form-lg);

  /* outlines */
  --outline-minimal: rgba(255, 255, 255, 0.1);
  --outline-default: rgba(255, 255, 255, 0.2);
  --outline-hover: rgba(255, 255, 255, 0.3);
  --outline-active: rgba(255, 255, 255, 0.6);

  --outline-inverted-minimal: rgba(0, 0, 0, 0.1);
  --outline-inverted-default: rgba(0, 0, 0, 0.2);
  --outline-inverted-hover: rgba(0, 0, 0, 0.3);
  --outline-inverted-active: rgba(0, 0, 0, 0.6);

  /* shadows */
  --shadow-default: 0px 0px 2px rgba(255, 255, 255, 0.2);
  --shadow-hover: -1px 0px 4px rgba(255, 255, 255, 0.2);
  --shadow-active: 1px 0px 1px rgba(255, 255, 255, 0.2);

  --shadow-dark-medium: 0px 5px 10px rgba(0, 0, 0, 0.4);
  --shadow-dark-large: 0px 10px 20px rgba(0, 0, 0, 0.4);

  /* colors */
  --red-xdark: #500000;
  --red-dark: #bf0930;
  --red-main: #ff3950;
  --red-light: #ff89a0;
  --red-text: var(--red-main);

  --green-dark: rgb(0, 92, 0);
  --green-main: rgb(32, 144, 32);
  --green-light: rgb(192, 230, 192);
  --green-text: var(--green-main);

  --blue-dark: #244aff;
  --blue-main: #548aff;
  --blue-light: #94caff;
  --blue-text: var(--blue-main);

  --orange-dark: #dd6400;
  --orange-main: #ee8f00;
  --orange-light: #ffbf00;
  --orange-text: var(--orange-main);

  --purple-dark: #8c10af;
  --purple-main: #ac30ff;
  --purple-light: #cc80ff;
  --purple-text: var(--purple-main);

  --blurple: #5865f2;

  --white: #ffffff;
  --black: #202020;

  --link-color: var(--blue-text);

  --darken: rgba(32, 32, 32, 0.8);

}

* {
  box-sizing: border-box;
  line-height: var(--line-height-md);
}

html {
  position: relative;
  height: 100%;
  width: 100%;
}

body {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-md);
  font-style: normal;
  background-color: var(--background-primary);
  color: var(--color-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: var(--font-weight-bold);
}

ul.menu,
ul.menu li,
ul.menu ul li {
   margin:0;
   padding: 0;
   text-indent: 0;
   list-style-type: none;
   cursor: default;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]::after {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  content: attr(data-tooltip);
  left: var(--unit-sm);
  right: var(--unit-sm);
  top: calc(100% + var(--unit-sm));
  display: flex;
  justify-content: center;
  border-radius: 3px;
  box-shadow: var(--shadow-dark-medium);
  background-color: var(--background-tertiary);
  color: var(--color-secondary);
  border: 1px solid var(--outline-default);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-xs);
  z-index: 10;
  padding: var(--unit-sm);
  transform: translateY(-20px);
  transition: all 150ms cubic-bezier(.25, .8, .25, 1);
}

[data-tooltip]:hover::after {
  opacity: 1;
  transform: translateY(0);
  transition-duration: 300ms;
}

body a:link, a:visited, a:active {
  color: var(--link-color);
  text-decoration: none;
}

body a:hover {
  color: var(--link-color);
  text-decoration: underline;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}