[data-component="EditorPage"] {

  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background: #fff;

  .editor-left {
    width: 250px;
    position: relative;
    flex-shrink: 0;
  }

  .editor-right {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;

    .file-tabs {
      flex-shrink: 0;
    }

    .text-editor {
      position: relative;
      flex-shrink: 0;
      flex-grow: 1;
    }

  }

}

control[control] {
  color: var(--black);
  a {
    text-decoration: none !important;
  }
}